body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

.bg-main {
    background-color: #F3F6CC;
}

.text-slate-blue {
    color: #1A485E;
}

.text-center {
    text-align: center;
}

.justify-center {
    justify-content: center;
}

.flexx {
    display: flex;
}

.align-center {
    align-items: center;
}

.h-screen {
    height: 100vh;
}

.space-y > :not(:last-child) {
    margin-bottom: 36px;
}

.space-x > :not(:last-child) {
    margin-right: 16px;
}

.required {
    margin-right: 4px;
    color: red;
}