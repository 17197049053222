.login-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 205px;
}

.login-left {
    padding-top: 96px;
    margin: 0 48px;
}

.login-header {
    /*width: 505px;*/
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
}

.login-subheader {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
}

@media (max-width: 1080px) {
    /* For responsive */
}

@media (max-width: 1400px) {
}
